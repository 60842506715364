@font-face {
  font-family: "Neutra Text";
  font-style: normal;
  font-weight: 400;
  src: url("./assets/fonts/NeutraText-Book.otf") format("opentype"),
    url("./assets/fonts/NeutraText-Book.ttf") format("truetype");
}
@font-face {
  font-family: "Neutra Text";
  font-style: bold;
  font-weight: 700;
  src: url("./assets/fonts/NeutraText-Bold.otf") format("opentype"),
    url("./assets/fonts/NeutraText-Bold.ttf") format("truetype");
}
